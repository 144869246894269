<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Transactions from './transactions';

/**
 * Starter page component
 */
export default {
  page: {
    title: "Smart Exchange",
    meta: [{ name: "description" }]
  },
  components: { Layout, PageHeader, Transactions },
  data() {
    return {
      title: "Smart Exchange",
      items: [
      ]
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-lg-12 ">
            <Transactions/>
        </div>
    </div>
  </Layout>
</template>